body {
    background-color: white;
    font-family: Helvetica, Arial, sans-serif;
    height: fit-content;
    margin: 0px;
    padding: 0px;
}
div{
    display: flex;
}
/* Navigation */
#nav-arrow{
    position: fixed;
    top: 90vh;
    left: 50%;
    transform: translate(-50%,0%);
    font-size: 5vh;
    font-weight: 100;
    transition: all 300ms ease-in-out;
}
#nav-arrow:hover{
    transform: translate(-50%,10%);
    cursor: pointer;
}
/* Page 1 */
#page-1{
    height: 100vh;
    width: 100%;
    flex-direction: row;
}
.title-div{
    width: 60%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
/* .cover-photo{
    position: absolute;
    width: 100vw;
    max-height: 100vh;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    opacity: 60%;
} */

.title-name{
    font-size: 8vh;
    text-align: start;
    margin: 0px;
    margin-left: 4vw;
    margin-right: 4vw;
    color: black;
}
.bio{
    margin-left: 4vw;
    margin-right: 4vw;
    font-size: 2vh;
    text-align: start;
    color: black;
}
.links{
    width: 40%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.link-group{
    height: 40%;
    width: 40%;
    flex-direction: column;
    justify-content: space-evenly;
}
.logo-link{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: white;
    border-radius: 7vh 0 0 7vh;
    transition: all 300ms ease-in-out;
}
@media (min-width: 550px) {

    .logo-link:hover{
        color: black;
    }

}
.logo{
    width: auto;
    height: 7vh;
    border: none;
    border-width: 0px;
    padding: 0px;
    border-radius: 7vh;
    transition: all 200ms ease;
}
.logo-ln{
    background-color: #1e8ced;
}
.logo-gh{
    background-color: #FBB040;
}
.logo-em{
    background-color: #f75268;  
}
.logo:hover{
    height: 7.5vh;
    background-color: white;
}
.logo-label{
    font-size: 2vh;
    text-decoration:none;
    padding-left: 1vw;
}

.tech-footer{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3vh;
    top: 88vh;
    font-weight: 400;
    
}

/* Page 2 */
.skills-name{
    font-size: 4.5vh;
    text-align: start;
    margin: 0px;
    margin-left: 4vw;
    margin-right: 4vw;
}
#body-page{
    height: 100vh;
    width: 100%;
    flex-direction: column;
}

.project-viewport{
    width: 100%;
    height: calc(100%/3);
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.sub{
    margin: 0px;
    margin-left: 10vw;
    font-weight: 300;
    font-size: 4vh;
}
.dtc-viewport{
    background-color:#1e8ced;
}

.dtc-text{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 6vh;
    margin-left: 10vw;
    text-decoration: none;
    color: white;
}
.dtc-sub{
    font-family: 'Montserrat', sans-serif;
}
.exp-viewport{
    background-color:#FBB040;
}

.exp-text{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 900;
    font-size: 6vh;
    margin-left: 10vw;
    text-decoration: none;
    color: white;
}
.exp-sub{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;;
}
.trk-viewport{
    background-color:#f75268;
}

.trk-text{
    font-family:'Major Mono Display', monospace;
    font-weight: 900;
    font-size: 6vh;
    margin-left: 10vw;
    text-decoration: none;
    color: white;
}
.trk-sub{
    font-family:'Major Mono Display', monospace;
}
.work-footer{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3vh;
    top: 188vh;
    font-weight: 400;
}